<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white">
        <div class="">
          <warranty-analysis-list></warranty-analysis-list>
        </div>
        <div class="pt-8">
          <warranty-analysis-year-sku></warranty-analysis-year-sku>
        </div>
        <div class="pt-8">
          <warranty-analysis-year-dealer></warranty-analysis-year-dealer>
        </div>
        <div class="pt-8">
          <un-received-rma></un-received-rma>
        </div>
        <div class="pt-8">
          <un-received-rma-list></un-received-rma-list>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import WarrantyAnalysisList from './warrantyAnalysisSkuDealer.vue'
import WarrantyAnalysisYearSku from './warrantyAnalysisYearSkuDealer.vue'
import WarrantyAnalysisYearDealer from './warrantyAnalysisYear.vue'
import UnReceivedRma from './unReceivedRmaTCPD.vue'
import UnReceivedRmaList from './unReceivedRmaList.vue'
export default {
  components: {
    WarrantyAnalysisList,
    WarrantyAnalysisYearDealer,
    WarrantyAnalysisYearSku,
    UnReceivedRma,
    UnReceivedRmaList
},
  data () {
    return {
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    document.title = 'Dealer Network ◾ Warranty Analysis'
  },
  watch: {
  },
  methods: {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
</style>
