<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`">
        <div class=" mx-2 shadow-md relative" :class="isFilterOpen ? 'box col-span-9' : 'box col-span-12'">
          <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex items-center">
            <div>
              <p>Dealers.PetStop.com - Warranty Analysis</p>
              <p class="heading-4 text-text1">By SKU, Dealer</p>
            </div>
            <div>
              <button @click="exportReport()" :class="firstLoading && ExtFListingObj.length > 0 ? 'text-white bg-primary2 cursor-pointer' : 'cursor-not-allowed bg-gray-300 tet-text2 filter opacity-60'" style="letter-spacing: 1px;" class="px-2 cust_card heading-6 mr-8 centerLine h-8">
                Export to Excel
              </button>
            </div>
            <div class="top-0 absolute" :class="isFilterOpen ? '-right-4' : 'transition-all delay-75 -right-2'" >
              <button id="toggle" class="bg-gray-600 hover:bg-gray-700 transition delay-75 w-11 h-11 rounded-l-full " @click="openCloseFilter()">
                <span v-if="isFilterOpen"><i class="fas fa-caret-right h-6 w-6 text-white mt-1"></i></span>
                <p v-if="!isFilterOpen"><i class="fas fa-caret-left h-6 w-6 text-white mt-1"></i></p>
              </button>
            </div>
          </div>
          <div style="overflow-y: auto; white-space: nowrap;border-collapse: collapse; " class="table_containder relative mt-2 max-w-max">
            <div class="text-text2  min-w-min heaer_row border border-gray1 font-bold flex heading-6 py-1 items-center " style="position: sticky;top: 0;background: white;" v-if="ExtFListingObj.length > 0 && firstLoading">
              <div class="xsmall_col centerLine text-center pl-2" style="min-width:54px;!important">
                <span class="">Index</span>
              </div>
              <div class="small_col2 centerLine pl-1" >
                <span class="cursor-pointer" @click="sortByColumn(0, titleObj[0].sort, titleObj[0].value)">{{titleObj[0].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[0].value) && titleObj[0].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[0].value) && !titleObj[0].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="smallx_col centerLine">
                <span class="overFlowParaA" >{{titleObj[1].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[1].value) && titleObj[1].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[1].value) && !titleObj[1].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col centerLine" >
                <span class="overFlowParaA">{{titleObj[2].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[2].value) && titleObj[2].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[2].value) && !titleObj[2].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  centerLine">
                <span class="overFlowParaA" >{{titleObj[3].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[3].value) && titleObj[3].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[3].value) && !titleObj[3].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  centerLine">
                <span class="overFlowParaA">{{titleObj[4].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[4].value) && titleObj[4].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[4].value) && !titleObj[4].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  centerLine">
                <span class="overFlowParaA">{{titleObj[5].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[5].value) && titleObj[5].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[5].value) && !titleObj[5].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col centerLine">
                <span class="overFlowParaA">{{titleObj[6].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[6].value) && titleObj[6].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[6].value) && !titleObj[6].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col centerLine">
                <span class="overFlowParaA">{{titleObj[7].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[7].value) && titleObj[7].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[7].value) && !titleObj[7].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col centerLine">
                <span class="overFlowParaA">{{titleObj[8].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[8].value) && titleObj[8].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[8].value) && !titleObj[8].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
            </div>
            <div :style="`max-height:` +  cardHight + `px; !important`" class="">
              <div class="min-w-min flex border-r border-l border-b border-gray1 bg-white text-sm items-start py-1"  v-for="(value, indexA) in ExtFListingObj" :key="indexA" style="place-items:flex-start;">
                <div class="xsmall_col text-center">
                  <span class="heading-6-1 text-text1">{{indexA + 1}}</span>
                </div>
                <div class="small_col2 pl-1">
                  <span class="heading-6-1 text-text1">{{value.sku}}</span>
                </div>
                <div>
                  <div v-for="(data, indexB) in value.skuDetail" :key="indexB" class="flex py-0.5"  :class="{'bg-gray-50': indexB % 2}">
                    <div class="smallx_col overFlowParaA">
                      <span class="heading-6 text-text1" v-if="data.dealerName !== ''">{{data.dealerName}}</span>
                      <span class="heading-6 text-text1 pl-8" v-if="data.dealerName === ''">--</span>
                    </div>
                    <div class="small_col rightText">
                      <span class="heading-6 text-text1 ">{{data.qtyOrdered}}</span>
                    </div>
                    <div class="small_col rightText">
                      <span class="heading-6 text-text1 ">{{data.qtyInvoiced}}</span>
                    </div>
                    <div class="small_col rightText">
                      <span class="heading-6 text-text1 ">{{data.qtyShipped}}</span>
                    </div>
                    <div class="small_col rightText">
                      <span class="heading-6 text-text1 ">{{data.qtyRefunded}}</span>
                    </div>
                    <div class="small_col rightText">
                      <span class="heading-6 text-text1 ">{{data.qtyCanceled}}</span>
                    </div>
                    <div class="small_col rightText">
                      <span class="heading-6 text-text1 ">{{(data.qtyRefunded/data.qtyOrdered).toFixed(2)}}%</span>
                    </div>
                    <div class="small_col rightText">
                      <span class="heading-6 text-text1 ">{{(data.qtyCanceled/data.qtyOrdered).toFixed(2)}}%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="layout m-2" v-if="ExtFListingObj.length === 0 && firstLoading">
            <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
              <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <div>
                <p class=" font-medium heading-4">No record found.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box col-span-3 overflow-y-auto mx-2 shadow-md transition-transform delay-300 ease-in-out transition-2" id="mainDiv" v-if="isFilterOpen">
          <div class="bg-white">
            <div class=" bg-primary2 p-2 items-center justify-between flex h-11 sticky top-0">
              <div class="heading-4 text-white font-bold">Filter</div>
              <span class="centerLine">
                <div class=" text-white cursor-pointer hover:underline mr-2" @click="resetAll()">
                  <button style="letter-spacing: 1px;" class="border border-gray1 px-3 py-1 cust_card heading-7">Reset</button>
                </div>
                <div class=" text-white cursor-pointer hover:underline" @click="applyFilter()">
                  <button style="letter-spacing: 1px;" class="border border-gray1 px-3 py-1 cust_card heading-7">Apply</button>
                </div>
              </span>
            </div> 
            <div class="pt-1">
              <div v-if="filterObject.startDate !== '' || filterObject.endDate !== ''">
                <p class="text-text1 heading-6 font-bold pl-2">
                  <span class="text-text2 heading-6 font-bold">{{filterObject.startDate | DMMYYYYddd}}</span> 
                  To
                  <span class="text-text2 heading-6 font-bold">{{filterObject.endDate | DMMYYYYddd}}</span>
                </p>
              </div>
              <div class="pt-1 pl-1">
                <button style="letter-spacing: 1px"
                v-for="(data, index) in buttonFiltersArray" :key="index"
                  @click="getDateTimeForFilterHandler(index, true)"
                  :class="data.selected ? 'text-white bg-primary2' : 'text-primary2 bg-white hover:bg-blue-50'"
                  class="m-1 px-1 py-0.5 heading-7 rounded-md border border-primary2"
                  >{{data.title}}</button
                >
              </div>
            </div>
            <div class="divider m-1"></div>
            <div class="p-2 my-1 cust_card" >
              <div class="centerLine justify-between ">
                <div class="centerLine">
                  <span class=" text-text2 heading-6 font-bold">Dealer Name</span>
                  <div class="loader2 ease-linear ml-2" v-if="isDealerNameLoading"></div>
                </div>
                <div class="centerLine pt-2 pb-1 ">
                  <div class="pr-2 cursor-pointer" @click="showSearch = !showSearch">
                    <span v-if="!showSearch"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                    <p v-if="showSearch"><i class="fas fa-times h-4 w-4 text-gray4"></i></p>
                  </div>
                  <div class="pr-2 ">
                    <button  @click="resetDealerName()" style="letter-spacing: 0.5px;" class="border border-gray-400 rounded-md px-2 heading-7">Clear</button>
                    <!-- <p class="cursor-pointer" @click="resetDealerName()" v-if="filterObject.dealers.length !== 0 || dealerNameSearch !== ''"><i class="fas fa-solid fa-filter-circle-xmark h-4 w-4 text-gray4"></i></p> -->
                  </div>
                  <span @click="selectedAll('dealers')" class="centerLine" v-if="parentDealernameListing.length > 0">
                    <input type="checkbox" class="w-4 h-4 cursor-pointer text-primary2" v-model="dealerNameSelAll">
                      <div class="text-text1 pl-1 heading-6 cursor-pointer">
                        {{!dealerNameSelAll ? 'All' : 'Null'}}
                      </div>
                  </span>
                </div>
              </div>
              <div style="display:flex" class=" text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-7" v-if="showSearch">
                <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="Item SKU Search" id="custSearchInput1"
                  v-model="dealerNameSearch"
                >
                <span v-if="dealerNameSearch.length > 0" class="cursor-pointer"  @click="clearSku()">
                  <i class="fas fa-times h-4 w-4 text-gray4"></i>
                </span>
              </div>
              <div class="overflow-auto" style="max-height: 280px !important;" v-if="parentDealernameListing.length > 0">
                <div v-for="(data, index) in parentDealernameListing" :key="index">
                  <div class="centerLine" @click="selectData(data, index, 'dealers')" v-if="data.displayName !== ''">
                    <input type="checkbox" class="h-4 w-4 cursor-pointer text-primary2" v-model="data.isSelected" >
                    <p class=" text-text1 pl-1 heading-6 cursor-pointer">{{data.displayName}}</p>
                  </div>
                </div>
              </div>
              <div class="layout" v-if="parentDealernameListing.length === 0 && isRefresh">
                <div class="flex p-1 text-sm text-text1 bg-gray2 rounded items-center" role="alert">
                  <div>
                    <p class=" font-medium heading-5">No record found.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-2 my-1 cust_card group" >
              <div class="centerLine justify-between ">
                <div class="centerLine">
                  <span class=" text-text2 heading-6 font-bold">Status</span>
                  <div class="loader2 ease-linear ml-2" v-if="isStatusLoading"></div>
                </div>
                <div class="centerLine  pt-2 pb-1">
                  <div class="pr-2 cursor-pointer" @click="showStatusSearchBar = !showStatusSearchBar">
                    <span v-if="!showStatusSearchBar"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                    <p v-if="showStatusSearchBar"><i class="fas fa-times h-4 w-4 text-gray4"></i></p>
                  </div>
                  <div class="pr-2 ">
                    <button  @click="resetStatusList()" style="letter-spacing: 0.5px;" class="border border-gray-400 rounded-md px-2 heading-7 cursor-pointer">Clear</button>
                  </div>
                  <span @click="selectedAll('status')" class="centerLine cursor-pointer" v-if="parentStatusLising.length > 0">
                    <input type="checkbox" class="w-4 h-4 cursor-pointer" v-model="statusNameSelAll" >
                    <div class="text-text1 pl-1 heading-6 cursor-pointer">
                      {{!statusNameSelAll ? 'All' : 'Null'}}
                    </div>
                  </span>
                </div>
              </div>
              <div style="display:flex" class=" text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-7" v-if="showStatusSearchBar">
                <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="Item Name Search" id="custSearchInput2"
                  v-model="statusNameSearch"
                >
                <span v-if="statusNameSearch.length > 0" class="cursor-pointer"  @click="clearName()">
                  <i class="fas fa-times h-4 w-4 text-gray4"></i>
                </span>
              </div>
              <div class="overflow-auto" style="max-height: 280px !important;" v-if="parentStatusLising.length > 0">
                <div v-for="(data, index) in parentStatusLising" :key="index">
                  <div class="centerLine" @click="selectData(data, index, 'status')" v-if="data.displayName !== ''">
                    <input type="checkbox" class="h-4 w-4 cursor-pointer" v-model="data.isSelected" >
                    <p class=" text-text1 pl-1 heading-6 cursor-pointer overFlowParaA w-96">{{data.displayName}}</p>
                  </div>
                </div>
              </div>
              <div class="layout" v-if="parentStatusLising.length === 0 && isRefresh">
                <div class="flex p-1 text-sm text-text1 bg-gray2 rounded items-center" role="alert">
                  <div>
                    <p class=" font-medium heading-5">No record found.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white container" v-if="showCustomDatepicker" >
      <div class="popup_overlay">
        <CustomDateSelector :appliedFilters="filterObject" @closeSelectedDialog="customDateListener" v-if="showCustomDatepicker"/>
      </div>
    </div>
</div>
</template>
<script>
import axios from 'axios'
import deboucneMixin from '@/mixins/debounce.js'
import {downloadFile} from '@/utils/common.js'
import moment from 'moment'
import API from '@/api/App.js'
import CustomDateSelector from '@/View/components/customDateSelection'
import {getDayAccordingToLimits} from '@/utils/convertDateAndTime.js'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    CustomDateSelector,
  },
  mixins: [deboucneMixin],
  data () {
    return {
      showSearch: false,
      showStatusSearchBar: false,
      tempItemSkuList: [],
      tempItemNameList: [],
      dealerNameSelAll: false,
      statusNameSelAll: false,
      isFilterOpen: true,
      isRefresh: true,
      dealerNameSearch: '',
      statusNameSearch: '',
      searchLoader: false,
      ExtFListingObj: [],
      parentDealernameListing: [],
      parentStatusLising: [],
      requiredDataListing: [],
      itemListDebounce: () => {},
      itemSkuDebounce: () => {},
      isDealerNameLoading: false,
      isStatusLoading: false,
      titleObj: [
        {title: 'Sku', icon: '', value: 'sku', sort: true},
        {title: 'Dealer Name', icon: '', value: '', sort: false},
        {title: 'Qty Ordered', icon: '', value: '', sort: false},
        {title: 'Qty Invoiced', icon: '', value: '', sort: false},
        {title: 'Qty Shipped', icon: '', value: '', sort: false},
        {title: 'Qty Refunded', icon: '', value: '', sort: false},
        {title: 'Qty Canceled', icon: '', value: '', sort: false},
        {title: 'Refund Rate', icon: '', value: '', sort: false},
        {title: 'Cancel Rate', icon: '', value: '', sort: false},
      ],
      pagination: {
        sortBy: 'sku',
        descending: true
      },
      buttonFiltersArray: [
        {
          title: 'Today',
          selected: false,
          value: 'today'
        },
        {
          title: 'Yesterday',
          selected: false,
          value: 'yesterday'
        },
        {
          title: 'Last Week',
          selected: false,
          value: 'lastWeek'
        },
        {
          title: 'Last 7 Days',
          selected: false,
          value: 'thisWeek'
        },
        {
          title: 'Last Month',
          selected: false,
          value: 'lastMonth'
        },
        {
          title: 'Last 30 Days',
          selected: true,
          value: 'last30Days'
        },
        {
          title: 'Last 60 Days',
          selected: false,
          value: 'last60Days'
        },
        {
          title: 'Last 90 Days',
          selected: false,
          value: 'last90Days'
        },
        {
          title: 'Last 120 Days',
          selected: false,
          value: 'last120Days'
        },
        {
          title: 'Custom',
          selected: false,
          value: 'custom'
        }
      ],
      selectedValue: 'last30Days',
      filterObject: {
        dealers: [],
        status: [],
        startDate: '',
        endDate: '',
        minDate: '',
        maxDate: '',
      },
      cardHight: 0,
      mainHeight: 0,
      defaultStartDate: '',
      defaultEndDate: '',
      showCustomDatepicker: false,
      firstLoading: false,
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    this.itemListDebounce = this.debounce(function () {
      this.getItemList(false)
    }, 500)
    this.itemSkuDebounce = this.debounce(function () {
      this.getItemSkuList(false)
    }, 500)
    this.cardHight = window.innerHeight - 171
    this.mainHeight = window.innerHeight - 90
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    this.defaultEndDate = new Date()
    let currentDate = new Date()
    this.defaultStartDate = new Date(currentDate.setDate(currentDate.getDate() - 29))
    this.filterObject.startDate = this.defaultStartDate
    this.filterObject.endDate = this.defaultEndDate
    this.apiCaller()
  },
  watch: {
    dealerNameSearch: {
      handler() {
        this.itemSkuDebounce()
      }
    },
    statusNameSearch: {
      handler() {
        this.itemListDebounce()
      }
    },
    tempItemSkuList: {
      handler () {
        console.log('tempItemSkuList', this.tempItemSkuList)
        this.filterObject.dealers = []
        this.tempItemSkuList.forEach(a => {
          if (a.displayName !== '') {
            this.filterObject.dealers.push(a.displayName)
          }
        })
      },
      deep: true
    },
    tempItemNameList: {
      handler () {
        console.log('tempItemNameList', this.tempItemNameList)
        this.filterObject.status = []
        this.tempItemNameList.forEach(a => {
          if (a.displayName !== '') {
            this.filterObject.status.push(a.displayName)
          }
        })
      },
      deep: true
    },
    filterObject: {
      handler () {
        console.log('filterObject', this.filterObject)
      },
      deep: true
    },
  },
  methods: {
    selectedAll (value) {
      if (value === 'dealers') {
        this.tempItemSkuList = []
        if (this.dealerNameSelAll === true) {
          this.dealerNameSelAll = false
        } else {
          this.dealerNameSelAll = true
        }
        if (this.dealerNameSearch === '') {
          if (this.dealerNameSelAll) {
            console.log('stage 1')
            this.tempItemSkuList = []
            for (let index = 0; index < this.parentDealernameListing.length; index++) {
              this.parentDealernameListing[index].isSelected = true
            }
          } else if (!this.dealerNameSelAll) {
            this.tempItemSkuList = []
            for (let index = 0; index < this.parentDealernameListing.length; index++) {
              this.parentDealernameListing[index].isSelected = false
              this.tempItemSkuList = []
            }
          }
        } else if (this.dealerNameSearch !== '') {
          if (this.dealerNameSelAll) {
            console.log('stage 3')
            for (let index = 0; index < this.parentDealernameListing.length; index++) {
              if (this.parentDealernameListing[index].isSelected === false) {
                this.parentDealernameListing[index].isSelected = true
                this.tempItemSkuList.push(this.parentDealernameListing[index])
              }
            }
          } else if (!this.dealerNameSelAll) {
            console.log('stage 4')
            this.tempItemSkuList = []
            for (let J = 0; J < this.parentDealernameListing.length; J++) {
              this.parentDealernameListing[J].isSelected = false
            }
          }
        }
      } else if (value === 'status') {
        this.tempItemNameList = []
        if (this.statusNameSelAll === true) {
          this.statusNameSelAll = false
        } else {
          this.statusNameSelAll = true
        }
        if (this.statusNameSearch === '') {
          if (this.statusNameSelAll) {
            console.log('stage 1')
            this.tempItemNameList = []
            for (let index = 0; index < this.parentStatusLising.length; index++) {
              this.parentStatusLising[index].isSelected = true
            }
          } else if (!this.statusNameSelAll) {
            this.tempItemNameList = []
            for (let index = 0; index < this.parentStatusLising.length; index++) {
              this.parentStatusLising[index].isSelected = false
              this.tempItemNameList = []
            }
          }
        } else if (this.statusNameSearch !== '') {
          if (this.statusNameSelAll) {
            console.log('stage 3')
            for (let index = 0; index < this.parentStatusLising.length; index++) {
              if (this.parentStatusLising[index].isSelected === false) {
                this.parentStatusLising[index].isSelected = true
                this.tempItemNameList.push(this.parentStatusLising[index])
              }
            }
          } else if (!this.statusNameSelAll) {
            console.log('stage 4')
            this.tempItemNameList = []
            for (let J = 0; J < this.parentStatusLising.length; J++) {
              this.parentStatusLising[J].isSelected = false
            }
          }
        }
      }
    },
    resetAll () {
      this.filterObject.startDate = this.defaultStartDate
      this.filterObject.endDate = this.defaultEndDate
      this.resetDealerName()
      this.resetStatusList()
      for (let i = 0; i < this.buttonFiltersArray.length; i++) {
        if (this.buttonFiltersArray[i].value === 'last30Days') {
          this.selectedValue = 'last30Days'
          this.buttonFiltersArray[i].selected = true
        } else {
          this.buttonFiltersArray[i].selected = false
        }
      }
    },
    resetDealerName () {
      this.dealerNameSearch = ''
      this.dealerNameSelAll = false
      this.filterObject.dealers = []
      this.tempItemSkuList = []
      for (let index = 0; index < this.parentDealernameListing.length; index++) {
        this.parentDealernameListing[index].isSelected = false
      }
    },
    resetStatusList () {
      this.statusNameSearch = ''
      this.statusNameSelAll = false
      this.filterObject.status = []
      this.tempItemNameList = []
      for (let index = 0; index < this.parentStatusLising.length; index++) {
        this.parentStatusLising[index].isSelected = false
      }
    },
    clearSku () {
      this.dealerNameSearch = ''
      // this.getItemSkuList(false)
    },
    clearName () {
      this.statusNameSearch = ''
      // this.getItemList(false)
    },
    openCloseFilter () {
      this.isFilterOpen = !this.isFilterOpen
      console.log('openCloseFilter')
    },
    customDateListener (data) {
      console.log('datadatadatadatadatadata', data)
      this.showCustomDatepicker = false
      if (data !== null) {
        this.filterObject.startDate = data.startDate
        this.filterObject.endDate = data.endDate
        // this.getListing()
      } else {
        // this.filterObject.startDate = this.requiredDataListing.minDate
        // this.filterObject.endDate = this.requiredDataListing.maxDate
      }
    },
    selectData (data, index, value) {
      if (value === 'dealers') {
        if (!this.dealerNameSelAll) {
          data.isSelected = !data.isSelected
          if (data.isSelected) {
            this.tempItemSkuList.push(data)
          } else {
            const findex = this.tempItemSkuList.findIndex(x => x.displayName === data.displayName)
            this.parentDealernameListing[index].isSelected = false
            this.tempItemSkuList.splice(findex, 1)
          }
        } else {
          data.isSelected = !data.isSelected
          this.tempItemSkuList = []
          for (let I = 0; I < this.parentDealernameListing.length; I++) {
            if (this.parentDealernameListing[I].isSelected === true) {
              this.tempItemSkuList.push(this.parentDealernameListing[I])
            }
            this.dealerNameSelAll = false   
          }
        }
      } else if (value === 'status') {
        if (!this.statusNameSelAll) {
          data.isSelected = !data.isSelected
          if (data.isSelected) {
            this.tempItemNameList.push(data)
          } else {
            const findex = this.tempItemNameList.findIndex(x => x.displayName === data.displayName)
            this.parentStatusLising[index].isSelected = false
            this.tempItemNameList.splice(findex, 1)
          }
        } else {
          data.isSelected = !data.isSelected
          this.tempItemNameList = []
          for (let I = 0; I < this.parentStatusLising.length; I++) {
            if (this.parentStatusLising[I].isSelected === true) {
              this.tempItemNameList.push(this.parentStatusLising[I])
            }
            this.statusNameSelAll = false   
          }
        }
      }
    },
    getDateTimeForFilterHandler (index, showCustomDatepicker) {
      this.selectedValue = this.buttonFiltersArray[index].value
      console.log('showCustomDatepicker', index, showCustomDatepicker)
      for (let i = 0; i < this.buttonFiltersArray.length; i++) {
        if (i !== index) {
          this.buttonFiltersArray[i].selected = false
        } else {
          this.buttonFiltersArray[i].selected = true
        }
      }
      if (this.buttonFiltersArray[index].value !== 'today' && this.buttonFiltersArray[index].value !== 'custom') {
        getDayAccordingToLimits(this.buttonFiltersArray[index].value).then(val => {
          console.log('val123123', val)
          this.filterObject.startDate = val.startDate
          this.filterObject.endDate = val.endDate
          // this.getListing()
        })
      } else {
        let date = new Date(new Date().setHours(0, 0, 0, 0)).toUTCString()
        if (this.buttonFiltersArray[index].value === 'today') {
          this.filterObject.startDate = date
          this.filterObject.endDate = date
          // this.getListing()
        } else {
          this.showCustomDatepicker = showCustomDatepicker
        }
      }
    },
    applyFilter() {
      this.getListing()
    },
    apiCaller () {
      this.isRefresh = false
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
      API.WarrAnyDateRangeRequiredData(
        'date',
        response => {
          this.requiredDataListing = response.Data === null ? [] : response.Data
          console.log('this.requiredDataListing', this.requiredDataListing)
          this.filterObject.minDate = this.requiredDataListing.min
          this.filterObject.maxDate = this.requiredDataListing.max
          this.isRefresh = true
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.getItemSkuList(true)
        },
        error => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getItemSkuList (value) {
      this.isRefresh = false
      this.isDealerNameLoading = true
      API.WarrAnyGetDealerListApi(
        this.dealerNameSearch,
        response => {
          this.parentDealernameListing = response.Data === null ? [] : response.Data
          this.isRefresh = true
          if (this.tempItemSkuList.length > 0 && this.filterObject.dealers.length > 0) {
            for (let I = 0; I < this.parentDealernameListing.length; I++) {
              for (let J = 0; J < this.tempItemSkuList.length; J++) {
                if (this.tempItemSkuList[J].displayName === this.parentDealernameListing[I].displayName) {
                  this.parentDealernameListing[I].isSelected = true
                }
              }
            }
          }
          this.isDealerNameLoading = false
          if (value) {
            this.getItemList(true)
          }
        },
        error => {
          this.isDealerNameLoading = false
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getItemList () {
      this.isRefresh = false
      this.isStatusLoading = true
      API.WarrAnyGetStatusListApi(
        this.statusNameSearch,
        response => {
          this.parentStatusLising = response.Data === null ? [] : response.Data
          this.isRefresh = true
          if (this.tempItemNameList.length > 0 && this.filterObject.status.length > 0) {
            for (let I = 0; I < this.parentStatusLising.length; I++) {
              for (let J = 0; J < this.tempItemNameList.length; J++) {
                if (this.tempItemNameList[J].displayName === this.parentStatusLising[I].displayName) {
                  this.parentStatusLising[I].isSelected = true
                }
              }
            }
          }
          this.isStatusLoading = false
        },
        error => {
          this.isStatusLoading = false
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getListing () {
      this.firstLoading = false
      console.log('this.filterObject.startDate 123 123', this.filterObject.startDate)
      console.log('this.filterObject.endDate 123 123', this.filterObject.endDate)
      let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
      let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, it takes some time ...'})
      this.searchLoader = true
      if (this.filterObject.dealers.length === 0) {
        this.filterObject.dealers = []
      }
      if (this.filterObject.status.length === 0) {
        this.filterObject.status = []
      }
      API.WarrAnySkuDealeReport(
        utcSTART,
        utcEND,
        this.filterObject.dealers,
        this.filterObject.status,
        false,
        response => {
          this.ExtFListingObj = response.Data === null ? [] : response.Data
          console.log(response.Data)
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.searchLoader = false
          this.firstLoading = true
        },
        error => {
          this.resetPaginationValues = false
          this.searchLoader = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    async exportReport () {
      if (this.firstLoading && this.ExtFListingObj.length > 0) {
        let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
        let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
        this.searchLoader = true
        if (this.filterObject.dealers.length === 0) {
          this.filterObject.dealers  = []
        }
        if (this.filterObject.status.length === 0) {
          this.filterObject.status = []
        }
        let day1 = moment(this.filterObject.startDate).format("MM-DD-YY")
        let day2 = moment(this.filterObject.endDate).format("MM-DD-YY")
        let Time = 'Dealer Network Warranty Ana By SKU Dealer-' + day1 + '-To-' + day2
        let data = new FormData()
        data.append('from', utcSTART)
        data.append('to', utcEND)
        data.append('dealerNameList', this.filterObject.dealers.length > 0 ? JSON.stringify(this.filterObject.dealers) : '')
        data.append('statusNameList', this.filterObject.status.length > 0 ? JSON.stringify(this.filterObject.status) : '')
        data.append('export', true)
        await this.downloadAttachment('api/v1/analytics/dealerNetwork/skuBydealers', `${Time}` + '.xlsx', data)
      }
    },
    async downloadAttachment (subUrl, fileName, formData) {
      let url = this.$store.getters.liveServerUrl + subUrl
      // If Want To cancel request
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      let originalFileName = fileName
      this.$store.dispatch('CancelDownloadRequest', source)
      // Show loader while downloading
      this.$store.dispatch('SetDownloadLoader', true)
      // Calling Api and waiting for response
      let response = await downloadFile(formData, url, source)
      // Checking for status if 200 == success
      if (response.status === 200) {
        this.$store.dispatch('CancelDownloadRequest', null)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        console.log(window.URL.createObjectURL(new Blob([response.data])))
        link.setAttribute('download', originalFileName)
        document.body.appendChild(link)
        link.click()
        // CLosing loader and reseting text in loader
        this.$store.dispatch('SetDownloadLoader', false)
        this.$store.dispatch('SetDownloadMessage', '')
      } else {
        this.$store.dispatch('CancelDownloadRequest', null)
        this.$store.dispatch('SetDownloadMessage', '')
        this.$store.dispatch('SetDownloadLoader', false)
      }
    },
    sortByColumn (i, val1, val2) {
      console.log('i', i)
      console.log('val1', val1)
      console.log('val2', val2)
      this.titleObj[i].sort = !val1
      this.pagination.descending = val1
      this.pagination.sortBy = val2
      console.log('this.pagination.descending', this.pagination.descending)
      if (this.pagination.descending) {
        console.log('click 1')
        this.ExtFListingObj.sort(function (a, b) {
          console.log('a', a)
          console.log('b', b)
          if (val2 === 'sku') {
            var nameA = a.sku
            var nameB = b.sku
          }
          // console.log('nameA-------------------- 1', nameA)
          // console.log('nameB-------------------- 1', nameB)
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      } else {
        console.log('click 2')
        this.ExtFListingObj.reverse(function (a, b) {
          if (val2 === 'sku') {
            var nameA = a.sku
            var nameB = b.sku
          }
          console.log('nameB-------------------- 2', nameB)
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      this.titleObj[i].isDesc = !this.titleObj[i].isDesc
    },
  },
  destroyed() {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.small_col2 {
  min-width: 200px !important;
  width: 200px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col {
  min-width: 150px !important;
  width: 150px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
  justify-content: right;
}
.smallx_col {
  min-width: 250px !important;
  width: 250px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.progress-bar {
  height: 2px;
  background-color: #0a7ef9;
  width: 100%;
  overflow: hidden;
}

.button_position {
  position: absolute;
  z-index: 10!important;
  height: 0px!important;
  /* position: fixed; */
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #74b4f9;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.loader2 {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1f48c2;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1.2s linear infinite;
}
.centerLine {
  display: flex !important;
  align-items: center !important;
}
.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>
